/* eslint-disable @typescript-eslint/no-inferrable-types */
import axios from "axios";
import config from "../../config.json";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    const errorObject = {
      code: error.response.status,
      message: "",
    };
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      errorObject.message = error.response.data.message;
    }

    return Promise.reject(errorObject);
  }
);

const source = axios.CancelToken.source();
const timeoutDuration = 180000; //
export class BaseService {
  path: string = "";

  getFullURL = (url: string): string => {
    return `${config.BASE_URL}${this.path}${url}`;
  };

  getJobsFullURL = (url: string): string => {
    return `${config.JOBS_URL}/${url}`;
  };

  get = async (url: string, queryString: string = "") => {
    return await axios
      .get(
        `${this.getFullURL(url)}${queryString !== "" ? `?${queryString}` : ``}`,
        {
          cancelToken: source.token,
          timeout: timeoutDuration || 2000, // time in ms
        }
      )
      .then((response) => response.data)
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.code === "ECONNABORTED") {
          // timeout handling
          console.log("Request timed out");
        } else {
          console.log("Error:", error.message);
        }
      });
  };

  post = async (url: string, data: any) => {
    return (await axios.post(`${this.getFullURL(url)}`, data)).data;
  };

  put = async (url: string, data: any) => {
    return (await axios.put(`${this.getFullURL(url)}`, data)).data;
  };

  update = async (url: string, data: any) => {
    return (await axios.put(`${this.getFullURL(url)}`, data)).data;
  };

  del = async (url: string) => {
    return (await axios.delete(`${this.getFullURL(url)}`)).data;
  };

  postToMake = async (fullURL: string, data: any) => {
    return (await axios.post(fullURL, data)).data;
  };

  getToJobs = async (url: string) => {
    return (await axios.get(`${this.getJobsFullURL(url)}`)).data;
  };
}
